import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap/scss/bootstrap.scss'
import LoadScript from 'vue-plugin-load-script';
import VueGtag from "vue-gtag";

const app = createApp(App).use(i18n)
.use(i18n)
.use(LoadScript)
.use(router)
.use(store)
.use(VueGtag, {
	bootstrap: false,
	appName: 'Finsuiza A.G.',
	config: { id: "G-DPBGT43CZW" },
	pageTrackerScreenviewEnabled: true,
	pageTrackerEnabled: true
  }, router);
app.mount('#app')
