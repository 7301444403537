<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
 el: '#app',

}
</script>